// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-us-success-js": () => import("../src/pages/contact-us-success.js" /* webpackChunkName: "component---src-pages-contact-us-success-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-download-js": () => import("../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-agreement-js": () => import("../src/pages/license-agreement.js" /* webpackChunkName: "component---src-pages-license-agreement-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-waiting-list-success-js": () => import("../src/pages/waiting-list-success.js" /* webpackChunkName: "component---src-pages-waiting-list-success-js" */),
  "component---src-pages-waiting-list-js": () => import("../src/pages/waiting-list.js" /* webpackChunkName: "component---src-pages-waiting-list-js" */)
}

